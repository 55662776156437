<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-cities' }">{{
            $t("CITIES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            :isLoading="isLoadingCity"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>

          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="chip"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-if="firstLoader"
            :loading="firstLoader"
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
          <pulse-loader
            :loading="!firstLoader && isLoadingCity"
            class="text-center"
          ></pulse-loader>
          <v-overlay :value="!firstLoader && isLoadingCity">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton v-on:onDelete="onDelete()"></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingCity"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_CITY,
  UPDATE_CITY,
  DELETE_CITY
} from "@/modules/city/store/city.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

// import * as validationFunc from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      city: {},
      firstLoader: true,
      isEdit: true
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cities", route: { name: "list-cities" } },
      { title: "Edit City" }
    ]);
    this.getCity();
  },
  computed: {
    ...mapGetters(["isLoadingCity"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "city-name-input",
            groupId: "city-name-group",
            required: "required",
            feedback_id: "city-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: false,
            model: "name"
          }
        ]
      };
    }
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    getCity() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_CITY, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.city = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.city).then(
              () => {
                vm.prepareChangesCheck();
              }
            );
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-cities" });
          }
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      // eslint-disable-next-line no-unused-vars
      this.$store
        .dispatch(UPDATE_CITY, { id: vm.city.id, payload: this.form })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
              // this.$router.push({ name: "edit-city", params: { id: itemEdit.id } });
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-city" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-cities" });
            }
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_CITY, vm.city.id)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });

              vm.prepareChangesCheck().then(() => {
                vm.$router.push({ name: "list-cities" });
              });
            })
            .catch(function(response) {
              vm.$notify({
                group: "notify",
                type: "error",
                title: "Error",
                text: response.message
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
